.logo-image {
  width: 60px !important;
  height: 60px !important;
  display: block;
  margin: 0 auto -10px;
}

@media (max-width: 376px) {
  /* Exemplo de max-width para telas menores, como iPhone SE */
  .logo-image {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1024px) {
  .logo-image {
    width: 80px;
    height: 80px;
    margin: 0 auto -10px;
  }
}

